<template>
      <div>
            <!-- Table Container Card -->
            <b-card no-body class="mb-0">

                  <div class="m-2">

                        <!-- Table Top -->
                        <b-row>
                              <!-- Per Page -->
                              <b-col cols="12" md="2" class="">
                                    <label>Mostrar</label>
                                    <v-select v-model="tableSettings.perPage" :options="perPageOptions" :clearable="false"
                                          class="per-page-selector d-inline-block mx-50" />
                              </b-col>

                              <b-col md="2">
                                    <b-form-group>
                                          <v-select class="d-inline" v-model="tableSettings.input" label="title"
                                                :options="inputs" :reduce="val => val.value" placeholder="Título" />
                                    </b-form-group>
                              </b-col>
                              <b-col md="3">
                                    <b-form-group>
                                          <v-select class="d-inline" v-model="tableSettings.typeSearch" label="title"
                                                :options="typeSearch" :reduce="val => val.value"
                                                placeholder="Tipo de búsqueda" />
                                    </b-form-group>
                              </b-col>
                              <b-col md="3">
                                    <b-form-group>
                                          <b-form-input v-model="tableSettings.searchQuery" class="d-inline"
                                                style="width: 90%" placeholder="Buscar..." />
                                    </b-form-group>
                              </b-col>


                              <!-- Search -->
                              <b-col cols="12" md="2">
                                    <div class="align-items-center justify-content-end">
                                          <b-button variant="primary" @click="searchData(tableSettings)"
                                                class="btn-icon rounded-circle mr-1">
                                                <feather-icon icon="SearchIcon" />
                                          </b-button>
                                    </div>
                              </b-col>
                        </b-row>

                  </div>

                  <b-table ref="refRoleListTable" class="position-relative" :items="dataTable" responsive
                        :fields="tableColumns" primary-key="id" :sort-by.sync="tableSettings.sortBy" show-empty
                        empty-text="No se encontraron datos" :sort-desc.sync="tableSettings.sortDesc">
                        <template #cell(role)="data">
                              <div v-for="(item, index) in data.item.role" :key="index">
                                    <span>{{ item }}</span>
                              </div>
                        </template>
                        <!-- Column: Actions -->
                        <template #cell(actions)="data">
                              <div class="d-flex">
                                    <router-link :to="{ name: 'recobros', params: { id_recovery: data.item.id } }">
                                          <b-button @click="showFiled(data.item, action = 'editar')" variant="primary"
                                                class="btn-icon rounded-circle mr-1" v-b-tooltip.hover.v-primary title="Ver">
                                                <feather-icon icon="EyeIcon" />
                                          </b-button>
                                    </router-link>
                              </div>
                        </template>
                  </b-table>
                  <div class="mx-2 mb-2">
                        <b-row>

                              <b-col cols="12" sm="6"
                                    class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                    <span class="text-muted">Viendo del {{ dataMeta.from }} al {{ dataMeta.to }} de {{
                                          dataMeta.of }}
                                          registros</span>
                              </b-col>
                              <!-- Pagination -->
                              <b-col cols="12" sm="6"
                                    class="d-flex align-items-center justify-content-center justify-content-sm-end">

                                    <b-pagination v-model="tableSettings.page" :total-rows="totalRows"
                                          :per-page="tableSettings.perPage" first-number last-number class="mb-0 mt-1 mt-sm-0"
                                          prev-class="prev-item" next-class="next-item">
                                          <template #prev-text>
                                                <feather-icon icon="ChevronLeftIcon" size="18" />
                                          </template>
                                          <template #next-text>
                                                <feather-icon icon="ChevronRightIcon" size="18" />
                                          </template>
                                    </b-pagination>
                              </b-col>
                        </b-row>
                  </div>
            </b-card>
      </div>
</template>
        
<script>
extend('required', {
      ...required,
      message: 'El campo {_field_} es obligatorio'
});
import { extend } from 'vee-validate'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import {
      BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
      BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, BForm, VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import es from 'vee-validate/dist/locale/es'
export default {
      name: 'Recoveries',
      components: {
            BCard,
            BRow,
            BCol,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            vSelect,
            BFormGroup,
            BForm,
            ValidationProvider,
            ValidationObserver,
            VBTooltip
      },
      data() {
            return {
                  action: null,
                  form: {
                        id: null,
                        recobro_sin_prefijo: null,
                        nit: null,
                        nonbre: null,
                        recobro_con_prefijo: null,
                        no_Radicado_Ente: null,
                        fecfac: null,
                        fecha_prestacion: null,
                        edad: null,
                        informe_edad: null,
                        prescripcion: null,
                        corte: null,
                        entidad_Responsable_del_Pago: null,
                        recibos_cajas_notas: null,
                        firma_Correa_y_Cortes: null,
                        firma_Bilcoll: null,
                        entrega_abogados_ext: null,
                        vigencia_bilcoll: null,
                        edad_bilcoll: null,
                        precispcion_bilcoll: null,
                        fehca_entrega_bilcoll: null,
                        saldo: null,
                        vr_Aceptado: null,
                        vr_Cancelado: null,
                        observacion: null,
                        glosa_cartera_recobros: null,
                        golsa_en_conciliacion: null,
                        proceso_juridico: null,
                        sin_rta_auditoria_ente: null,
                  },
                  showModal: false,
                  update: false,
                  modal_password: false,
                  formDisabled: false,
                  isAddNewUserSidebarActive: false,
                  refRoleListTable: null,
                  perPageOptions: [10, 25, 50, 100],
                  searchQuery: '',
                  inputs: [
                        { value: 'recovery_with_prefix', title: 'Número de recobro' },
                        { value: 'radiated_number', title: 'Número de radicado' },
                  ],
                  typeSearch: [
                        { value: 'LIKE', title: 'Igual' },
                        { value: 'NOT LIKE', title: 'No es igual' },
                        { value: '>', title: 'Mayor que' },
                        { value: '<', title: 'Menor que' }
                  ],
                  tableColumns: [
                        { key: 'recovery_without_prefix', label: 'Cantidad recobros' },
                        { key: 'quantity_fileds', label: 'Cantidad recobros' },
                        { key: 'balance', label: 'Saldo' },
                        { key: 'vr_accepted', label: 'Vr Aceptado' },
                        { key: 'vr_canceled', label: 'Vr Cancelado' },
                        { key: 'actions', label: 'Acciones' }
                  ],
                  sortBy: 'id',
                  isSortDirDesc: true,
                  totalRows: 0,
                  dataMeta: {
                        from: 0,
                        to: 0,
                        of: 0,
                  },
                  dataTable: [],
                  showLoadingTable: false,
                  tableSettings: {
                        filter: this.$route.params && this.$route.params.id,
                        searchQuery: '',
                        input: '',
                        typeSearch: '',
                        perPage: 10,
                        page: 1,
                        sortBy: 'quantity_fileds',
                        sortDesc: true,
                  },
                  formDataEdit: {},
                  recoveries: [],
            }
      },
      directives: {
            Ripple,
            'b-tooltip': VBTooltip,
      },
      watch: {
            "tableSettings.sortBy": {
                  handler(val) {
                        this.fetchFiled()

                  },
            },
            "tableSettings.sortDesc": {
                  handler(val) {
                        this.fetchFiled()

                  },
            },
            "tableSettings.perPage": {
                  handler(val) {
                        this.fetchFiled()

                  },
            },
            "tableSettings.page": {
                  handler(val) {
                        this.fetchFiled()

                  },
            },
      },
      created() {
            this.fetchFiled()

      },
      methods: {
            searchData(data) {
                  this.$http.post('/recoveries/search', data)
                        .then(response => {
                              if (response.data.code == 200) {
                                    this.$swal({
                                          title: response.data.message,
                                          icon: 'success',
                                          customClass: {
                                                confirmButton: 'btn btn-success',
                                          },
                                          buttonsStyling: false,
                                    });
                                    this.dataTable = response.data.recoveries
                              }
                              if (response.data.code == 500) {
                                    this.$swal({
                                          title: response.data.message,
                                          icon: 'warning',
                                          customClass: {
                                                confirmButton: 'btn btn-warning',
                                          },
                                          buttonsStyling: false,
                                    })
                              }
                        })
                        .catch((error) => {
                              this.errors = error.response.data.errors;
                        });
            },
            validationForm(id) {
                  localize('es', es);
                  this.$refs.simpleRules.validate().then(success => {
                        if (success) {
                              this.updateUser(id);
                        }
                  })
            },
            validationFormChangePassword() {
                  localize('es', es);
                  this.$refs.formChangePassword.validate().then(success => {
                        if (success) {
                              this.change_password();
                        }
                  })
            },
            fetchFiled() {
                  this.showLoadingTable = true
                  this.$http.get('/fileds/list', { params: this.tableSettings }).then((response) => {
                        this.dataTable = response.data.fileds
                        this.totalRows = response.data.total
                        this.dataMetaCounter()
                        this.showLoadingTable = false
                  })
            },
            dataMetaCounter() {
                  const localItemsCount = this.dataTable.length
                  this.dataMeta.from = this.tableSettings.perPage * (this.tableSettings.page - 1) + (localItemsCount ? 1 : 0)
                  this.dataMeta.to = this.tableSettings.perPage * (this.tableSettings.page - 1) + localItemsCount
                  this.dataMeta.of = this.totalRows
            }
      },
}
</script>
        
<style lang="scss" scoped>
.per-page-selector {
      width: 90px;
}
</style>
        
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>